import React from 'react';
import '../../css/Page.css'
import logo  from '../../img/logoMod.jpeg';
import logoPng  from '../../img/wrap&co vettoriale.png';

import Card from './Card';
import CardReverse from './CardReverse';

import WrappingText from '../../testi/WrappingText';
import PpfText from '../../testi/PpfText';
import OscuramentoVetriText from '../../testi/OscuramentoVetriText';
import DetailingVetriText from '../../testi/DetailingText';
import ImbarcazioniText from '../../testi/ImbarcazioniText';
import ControlloSolareText from '../../testi/ControlloSolareText';
import InteriorDesignText from '../../testi/InteriorDesignText';
import SicurezzaText from '../../testi/SicurezzaText';
import LabelLinguage from './LabelLinguage';

import SlidrtImg from '../sezioni/SliderImg.js';


export default function Home() {


const textLingue = {
    IT : <>
        La Wrap&co nasce dalla pluriennale esperienza del titolare maturata nel campo
        delle vernici e della carrozzeria, sia nell’ambito delle azioni correttive che
        conservative.
        Stefano Crescenzi , proprietario e responsabile della ditta, spinto dalla forte
        passione per il mondo dell’automobile, ha deciso di creare un vero e proprio centro
        estetico per auto finalizzato ad accontentare quella parte di clientela più esigente
        che ricerca elevati standard di qualità in qualsiasi tipologia di lavorazione.
        Tutto ha avuto inizio qualche anno fa, quando il titolare, a seguito della frequenza
        di corsi professionali mirati ad apprendere tecniche e strategie avanzate per
        accrescere la professionalità già acquisita , ha ottenuto la qualifica di installatore
        certificato per la regione Marche nel mondo del PPF.
        Nonostante la consolidata attitudine e passione ad operare nel mondo dei motori, i
        servizi della Wrap&co si estendono anche alla sfera privata e professionale per
        garantire una copertura a 360 gradi riguardante la personalizzazione.
        La ditta è in costante aggiornamento, espansione e collaborazione con le più
        importanti aziende produttrici di pellicole, per garantire al cliente il prodotto più
        adatto alle proprie esigenze.
    </> ,
    EN : <>
        Wrap&co was born from the owner's many years of experience gained in the field
            of paints and bodywork, both in the context of corrective actions and
            conservative.
            Stefano Crescenzi, owner and manager of the company, driven by the strong
            passion for the automotive world, he decided to create a real center
            aesthetic for cars aimed at satisfying that part of the most demanding clientele
            that seeks high quality standards in any type of processing.
            It all began a few years ago, when the owner, following the attendance
            of professional courses aimed at learning advanced techniques and strategies for
            increase the professionalism already acquired, he obtained the qualification of installer
            certified for the Marche region in the PPF world.
            Despite the consolidated aptitude and passion for operating in the world of engines, i
            Wrap&co services also extend to the private and professional sphere for
            ensure 360-degree coverage regarding personalization.
            The company is constantly updating, expanding and collaborating with the most
            important film manufacturers, to guarantee the customer the best product
            suitable for your needs.
    </>,
    SP : <>
        Wrap&co nace de la experiencia de muchos años del propietario en el sector
        de pinturas y carrocerías, tanto en el contexto de acciones correctoras como
        conservador.
        Stefano Crescenzi, propietario y gerente de la empresa, impulsado por la fuerte
        pasión por el mundo del automóvil, decidió crear un verdadero centro
        estéticos para automóviles destinados a satisfacer esa parte de la clientela más exigente
        que busca altos estándares de calidad en cualquier tipo de procesamiento.
        Todo empezó hace unos años, cuando el propietario, siguiendo la frecuencia
        de cursos profesionales dirigidos al aprendizaje de técnicas y estrategias avanzadas para
        aumentar la profesionalidad ya adquirida, obtuvo el título de instalador
        certificado para la región Marche en el mundo PPF.
        A pesar de la aptitud consolidada y la pasión por operar en el mundo de los motores, yo
        Los servicios de Wrap&co se extienden también al ámbito privado y profesional para
        garantizar una cobertura de 360 ​​grados con respecto a la personalización.
        La empresa está en constante actualización, ampliación y colaboración con los más
        importantes fabricantes de películas, para garantizar al cliente el mejor producto
        adecuado para sus necesidades.
    </>
    
}

const textTitle = {
    IT : 'Chi siamo ?' ,
    EN : 'Who we are ?' ,
    SP : 'Quienes somos ?'
}

const lingua = localStorage.getItem("lingua") === null ? 'IT' : localStorage.getItem("lingua");


    return (
        <div className='p-3' > 
        <LabelLinguage />
            <div className="d-flex justify-content-center">
                <img src={logoPng} className='logo_home' alt="logo" />   

            </div>
            <div
            className='container_slider m-auto'
            // style={{
            //         //transform: 'translate(66%)' , 
            //         maxWidth: '920px'
            //     }}
            >
                <SlidrtImg />
            </div>



            <Card 
                text= {textLingue[lingua]}
                title= {textTitle[lingua]}
                logo={logo}
            />
            <a href='/Wrapping' className='text-decoration-none' >
                <CardReverse 
                    title={WrappingText()[lingua].domanda}
                    text={WrappingText()[lingua].testo}
                    logo={WrappingText()[lingua].ImmagineIntro}
                />
            </a>
            <a href="/PPF"  className='text-decoration-none' >
                <Card
                    title={PpfText()[lingua].domanda}
                    text={PpfText()[lingua].testo}
                    logo={PpfText()[lingua].ImmagineIntro}
                />
            </a>

            <a href="/OscuramentoVetri" className='text-decoration-none' >
                <CardReverse 
                    title={OscuramentoVetriText()[lingua].domanda}
                    text={OscuramentoVetriText()[lingua].testo}
                    logo={OscuramentoVetriText()[lingua].ImmagineIntro}
                />
            </a>

            <a href="/Detailing" className='text-decoration-none'>
                <Card
                    title={DetailingVetriText()[lingua].domanda}
                    text={DetailingVetriText()[lingua].testo}
                    logo={DetailingVetriText()[lingua].ImmagineIntro}
                />
            </a>
            
            <a href="/Imbarcazioni" className='text-decoration-none'>
                <CardReverse 
                    title={ImbarcazioniText()[lingua].domanda}
                    text={ImbarcazioniText()[lingua].testo}
                    logo={ImbarcazioniText()[lingua].ImmagineIntro}
                />
            </a>
            
            <a href="/ControlloSolare" className='text-decoration-none'>
                <Card
                    title={ControlloSolareText()[lingua].domanda}
                    text={ControlloSolareText()[lingua].testo}
                    logo={ControlloSolareText()[lingua].ImmagineIntro}
                />
            </a>
            
            <a href="/InteriorDesign" className='text-decoration-none'>
                <CardReverse 
                    title={InteriorDesignText()[lingua].domanda}
                    text={InteriorDesignText()[lingua].testo}
                    logo={InteriorDesignText()[lingua].ImmagineIntro}
                />
            </a>
            
            <a href="/Sicurezza" className='text-decoration-none'>
                <Card
                    title={SicurezzaText()[lingua].domanda}
                    text={SicurezzaText()[lingua].testo}
                    logo={SicurezzaText()[lingua].ImmagineIntro}
                />
            </a>
        </div>
    )
}
