import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import imagetest1  from '../../img/Home/23C03BB9-9B94-46A7-9191-F7071EDEE8EC-L0-001.webp';
import imagetest2  from '../../img/Home/00AF348C-14AD-45C6-A990-DCDACA31190E-L0-001.webp';
import imagetest3  from '../../img/Home/0FBDBD12-2310-4AE5-8265-E44642EE4DEA-L0-001.webp';
import imagetest4  from '../../img/Home/0FE56F9A-84F5-4A37-93CB-0A0FF0040234-L0-001.webp';
import imagetest5  from '../../img/Home/1AB48B9C-F1F4-43C9-AA3A-24EB4AA0BCC8-L0-001.webp';

import imagetest9  from '../../img/Home/04A51BF6-2EC1-4B77-8DAD-AE094C05A61E-L0-001.webp';


import imagetest11  from '../../img/Home/24CDA176-94D5-4889-81FA-0EDC5EBF6629-L0-001.webp';


class SlidrtImg extends React.Component {
  render() {
    var settings = {
      dots: true,
      autoplay : true ,
      //className : 'w-50',
      autoplaySpeed : 2000
    };
    let container = {
        padding: '40px' ,
        background: '#419be0'
    }

    
    const arrayImageSlide = [
        'imagetest1' ,
        'imagetest2' ,
        'imagetest3' ,
        'imagetest4' ,
        'imagetest5' ,
        'imagetest9' ,
        'imagetest11' ,
    ]

    const arrayUrlImage = {
        imagetest1 : imagetest1 ,
        imagetest2 : imagetest2 ,
        imagetest3 : imagetest3 ,
        imagetest4 : imagetest4 ,
        imagetest5 : imagetest5 ,
        imagetest9 : imagetest9 ,
        imagetest11 : imagetest11 ,
    }

    const arrayClassImageGallery = {
      imagetest1 : '' ,
      imagetest2 : '' ,
      imagetest3 : 'ration_rigth' ,
      imagetest4 : 'ration_rigth' ,
      imagetest5 : '' ,
      imagetest9 : '' ,
      imagetest11 : '' 
  } 

    return (
      <div className={container}>
        <Slider {...settings}>
            {arrayImageSlide.map(image => {
                return(
                    <div >
                        <img 
                            src={arrayUrlImage[image]} 
                            //style={{height: '500px'}}
                            className={"m-auto img_slide "+arrayClassImageGallery[image]}
                            alt="..."
                        />
                    </div>
                )
            })}
        </Slider>
      </div>
    );
  }
}

export default SlidrtImg
