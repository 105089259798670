import img1 from '../img/controllo solare/img1.webp';
import img2 from '../img/controllo solare/img2.webp';
import img3 from '../img/controllo solare/img3.webp';
import img4 from '../img/controllo solare/img4.webp';
import img5 from '../img/controllo solare/img5.webp';



export default function ControlloSolareText(params) {
    
    let arrayImg = [
        img1 ,
        img2 ,
        img3 ,
        img4 ,
        img5 
    ]
    const resp = {
        IT : {
            titolo : 'CONTROLLO SOLARE' ,
            domanda : 'PROTEGGI TE E LA TUA MERCE!',
            testo : 
                <div>
                    Il controllo solare si ottiene tramite pellicole in poliestere più o meno trasparenti,
                    finalizzate a risolvere problemi di eccessivo calore, abbagliamento del sole,
                    scolorimento dai raggi UV, fuoriuscita di calore in inverno, ecc..
                    Nel mercato sono presenti varie gradazioni e tonalità con proprietà differenti per
                    accontentare e soddisfare anche la richiesta più esigente e particolare.
                    L’applicazione di queste pellicole solari sugli infissi, oltre al vantaggio estetico,
                    determina …
                    <br /> -notevole risparmio energetico
                    <br /> -climatizzazione uniforme in tutte le stanze
                    <br /> -riduzione del riflesso del sole
                    <br /> -conservazione degli interni (mobilio e/o merce esposta)<br />
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1,
            arrayClassImageGallery : {
                img1:'' ,
                img2:'' ,
                img3:'' ,
                img4:'' ,
                img5:'ration_rigth mt-5' 
            }
            
        
        } ,
        
        EN : {
            titolo : 'SOLAR CONTROL' ,
            domanda : 'PROTECT YOURSELF AND YOUR GOODS!',
            testo : 
                <div>
                    Solar control is achieved by means of more or less transparent polyester films designed to
                    solve problems of excessive heat, sun glare, discolouration from UV rays, heat loss in winter,
                    etc.
                    Various shades and tones with different properties are available on the market to satisfy and
                    satisfy even the most demanding and particular request.
                    The application of these solar films on windows and doors, in addition to the aesthetic
                    advantage, results in ...
                    <br /> -remarkable energy savings
                    <br /> -uniform climate control in all rooms
                    <br /> -reduction of sun glare
                    <br /> -conservation of the interior (furniture and/or goods on display)<br />
                </div> ,
            arrayImageGallery : arrayImg ,
            ImmagineIntro : img1,
            arrayClassImageGallery : {
                img1:'' ,
                img2:'' ,
                img3:'' ,
                img4:'' ,
                img5:'ration_rigth mt-5' 
            }
            
        
        } ,
        
        SP : {
            titolo : 'CONTROL SOLAR' ,
            domanda : 'PROTEJA SUS BIENES Y A USTED MISMO',
            testo : 
                <div>
                    El control solar se consigue mediante películas de poliéster más o menos transparentes diseñadas para resolver problemas de calor excesivo, deslumbramiento solar, decoloración por los rayos UV, pérdida de calor en invierno, etc.
                    En el mercado existen varios tonos y tonalidades con distintas propiedades para satisfacer y satisfacer hasta la petición más exigente y particular.
                    La aplicación de estas láminas solares en ventanas y puertas, además de la ventaja estética, resulta ...
                    <br /> -un notable ahorro energético
                    <br /> -climatización uniforme en todas las habitaciones
                    <br /> -reducción del resplandor solar
                    <br /> -conservación del interior (mobiliario y/o bienes expuestos)<br />
                </div> ,
            arrayImageGallery : arrayImg ,
            ImmagineIntro : img1,
            arrayClassImageGallery : {
                img1:'' ,
                img2:'' ,
                img3:'' ,
                img4:'' ,
                img5:'ration_rigth mt-5' 
            }
            
        
        } ,

    }
    
    return resp
    
}


