import React from 'react';
import instagram from '../img/Instagram-logo.png';
import whatsapp  from '../img/WhatsApp.png';
import logo  from '../img/wrap&co vettoriale.png';
import fb from '../img/facebook.png';
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import ReactWhatsapp from 'react-whatsapp';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet } from "react-router-dom";




export default function Homepage({Main}) {
    //console.log(Main , ' Main');
    console.log(window.location.pathname , window.location.pathname === '/auto');
    
    const menu = {
        Home : 'Home' ,
        Wrapping : 'Wrapping' ,
        PPF : 'PPF' ,
        OscuramentoVetri : 'Oscuramento Vetri' ,
        Detailing : 'Detailing' ,
        Imbarcazioni : 'Imbarcazioni' ,
        InteriorDesign : 'Interior Design' ,
        ControlloSolare : 'Controllo Solare' ,
        Sicurezza : 'Sicurezza' ,
        Contatti : 'Contatti' ,

    }

    return(
        <div className='homepage'>
            <header>
                <div id='menu_desktop' className='border-end' style={{width : '16rem'}}  onClick={()=> window.location.pathname = '/Home'} >
                    <div>
                        {/* LOGO */}
                        <img src={logo} className='w-100' alt="logo" />
                    </div>
                    <div>
                        {/* Menu */}
                        <Nav className="me-auto d-flex flex-column fs-6">
                            {
                                Object.keys(menu).map(item => {
                                    return(
                                        <Nav.Link className={ window.location.pathname === '/' + item ? 'pageSelection text-white ' : 'text-white '} href={'/'+ item}> { menu[item]}</Nav.Link>
                                    )
                                })
                            }
                            </Nav>
                    </div>
                </div>
                <div id="manu-mobile-tablet">
                    <Navbar className='border-bottom border-2' collapseOnSelect expand="lg" bg="dark" variant="dark">
                        <Container className='d-sm-flex mobile_box menu_tendina' >
                            <Navbar.Brand href="#home">
                                <img 
                                    src={logo}
                                    id='logo_ditta'
                                    alt="logo" 
                                    className='w-100'
                                />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                            {
                                Object.keys(menu).map(item => {
                                    return(
                                        <Nav.Link className={ window.location.pathname === '/' + item ? 'pageSelection text-white ' : 'text-white '} href={'/'+ item}> { menu[item]}</Nav.Link>
                                    )
                                })
                            }
                            </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                </div>
                <div id='main' style={{width: '100%'}}>

                    {/* Whatsapp */}
                        <ReactWhatsapp 
                            number="+393791051477" 
                            message="Ciao vorrei informazioni su"
                            className='btn btn_whatsapp'
                        >
                            <img 
                                src={whatsapp} 
                                alt="Whatsapp"
                                style={{width : '55px'}}
                                //className='img_whatsapp'
                            />
                        </ReactWhatsapp>
                    {/* Whatsapp */}

                    {/* Pagina */}
                        <div>
                        {Main && <Main />}
                        <Outlet />
                        </div>
                    {/* Pagina */}
                </div>
            </header>
            <footer>
                <div className="d-flex flex-column green_b h-100 p-lg-3 p-md-3 w_33">

                    <a 
                        className='link_social m-lg-2 m-md-2 mt-2'
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/officialwrapandco/" 
                    >
                        <img style={{ margin: '0 6px'}} className='social_img' src={instagram} alt="logo_Instagram" />
                        <h5>@officialwrapandco</h5>
                    </a>

                    <a 
                        className='link_social m-lg-2 m-md-2 mt-2'
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/profile.php?id=100083300501018" 
                    >
                        <img style={{margin: '0px 12px'}} className='social_img' src={fb} alt="logo_Instagram" />
                        <h5 className='m-0' >@Wrap&co</h5>
                    </a>

                    <a href='tel:3791051477' className='align-items-center d-flex link_social m-lg-2 m-md-2 mt-2'>
                        <FontAwesomeIcon className='loghi' style={{margin:'0 15px'}} icon={faPhone} />
                        <h5 className='m-0'>3791051477</h5>
                    </a>

                    <a
                        className='align-items-center d-flex link_social m-lg-2 m-md-2 mt-2'
                        href= "mailto:infowrapandco@gmail.com"
                    >
                        <FontAwesomeIcon className='loghi' style={{margin:'0 15px'}} icon={faEnvelope} />
                        <h5 className='m-0'>infowrapandco@gmail.com</h5>
                    </a>

                </div>
                <div className="w_33 h-100 bg-white"></div>
                <div className="w_33 h-100 red_b"></div>
            </footer>
        </div>
    )
}