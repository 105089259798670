import ita from '../../img/bandiera-italiana.jpg'
import eng from '../../img/bandiera-inglese.jpg'
import spagna from '../../img/Bandiera-Spagna.webp'

import { useState } from 'react';

export default function LabelLinguage() {

    const [viewLingue , setViewLingue] = useState(false)

    const lingua = localStorage.getItem("lingua") === null ? 'IT' : localStorage.getItem("lingua");
    const cambiaLingua = (event) => {
        localStorage.setItem("lingua", event)
        console.log(event , 'ligua');
        window.location.reload()
    }
    const immagine = lingua === 'IT' ? ita : (lingua === 'EN' ? eng : spagna) ;

    return(
        <div className='w-100' >
            {!viewLingue ?
            <button className='btn float-end' onClick={()=>setViewLingue(true)}>
                <img style={{width: '45px'}} src={immagine} alt="" />
            </button>
            :
            <ul className="list-group float-end">
                <li onClick={()=>cambiaLingua('IT')} className="list-group-item"><img style={{width: '45px'}} src={ita} alt="" /></li>
                <li onClick={()=>cambiaLingua('EN')}  className="list-group-item"><img style={{width: '45px'}} src={eng} alt="" /></li>
                <li onClick={()=>cambiaLingua('SP')}  className="list-group-item"><img style={{width: '45px'}} src={spagna} alt="" /></li>
            </ul>}
        </div>
    )
}