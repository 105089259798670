import img1 from '../img/imbarcazioni/img1.webp' ;


export default function ImbarcazioniText(params) {
    const resp = {
        IT :{
            titolo : 'IMBARCAZIONI' ,
            domanda : 'PERCHE’ CAMBIARE SE SI PUO’ RINNOVARE?',
            testo : 
                <div>
                    La Wrap&amp;co mette a disposizione di tutti coloro che posseggono un’imbarcazione
                    un’esperienza pluriennale nel mondo delle vernici e della carrozzeria.<br /> 
                    In questo ambito offriamo servizi di lucidatura completi a 360 gradi, anche con
                    trattamenti nanoceramici.<br /> 
                    Offriamo inoltre il servizio di applicazione di scritte, grafiche e adesivi vari desiderati
                    dal Cliente che rendono l&#39;imbarcazione unica e personalizzata.
                    Siamo inoltre specializzati nel rinnovo e nella riqualificazione del mobilio interno,
                    attraverso particolari pellicole di vari tipi e finiture: dal semplice legno, al marmo,
                    alla pelle, al metallo e molto altro…<br /> 
                    Cosa aspetti a descriverci il tuo sogno?<br /> 
                    Siamo qui per farlo divenire realtà!<br /> 
                </div> ,
            arrayImageGallery : [
                img1
            ],
            ImmagineIntro : img1
            
        } ,
        EN :{
            titolo : 'BOATS' ,
            domanda : 'WHY CHANGE IF YOU CAN RENOVATE?',
            testo : 
                <div>
                    Wrap&co offers all boat owners many years of experience in the world of paint and bodywork.
                    In this field, we offer complete 360° polishing services, including nanoceramic treatments.
                    We also offer the service of applying various inscriptions, graphics and stickers desired by the
                    customer that make the boat unique and personalised.
                    We also specialise in the renovation and upgrading of interior furniture, through special films
                    of various types and finishes: from simple wood, to marble, leather, metal and more...
                    What are you waiting for to tell us your dreams?
                    We are here to make them come true!
                </div> ,
            arrayImageGallery : [
                img1
            ],
            ImmagineIntro : img1
            
        } ,
        SP :{
            titolo : 'BARCOS' ,
            domanda : '¿POR QUÉ CAMBIAR SI SE PUEDE RENOVAR?',
            testo : 
                <div>
                    Wrap&co ofrece a todos los propietarios de embarcaciones muchos años de experiencia en el mundo de la pintura y la carrocería.
                    En este campo, ofrecemos servicios completos de pulido a 360°, incluidos los tratamientos de nanocerámica.
                    También ofrecemos el servicio de aplicar diversas inscripciones, gráficos y pegatinas deseados por el cliente que hacen que la embarcación sea única y personalizada.
                    También estamos especializados en la renovación y actualización de muebles de interior, mediante láminas especiales de diversos tipos y acabados: desde la simple madera, hasta el mármol, el cuero, el metal y mucho más...
                    ¿A qué esperas para contarnos tu sueño? Estamos aquí para hacerlo realidad.
                </div> ,
            arrayImageGallery : [
                img1
            ],
            ImmagineIntro : img1
            
        } ,
    }
    
    return resp

}