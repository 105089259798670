import React from 'react';

import LabelLinguage from './sezioni/LabelLinguage'



export default function PageController({
    titolo ,
    ImmagineIntro ,
    domanda ,
    testo ,
    arrayImageGallery = [] ,
    altroContenutoPreGallery,
    arrayClassImageGallery={}
}) {
    
    return(
        <div className='w-100 align-items-center d-flex flex-column p-3'>
            <div className='w-100 image_officina_bk'>
                <div  className='titlePage'>
                   <h1 style={{ fontSize : '211%' }} >{titolo} </h1>
                </div>

                <div>
                    <LabelLinguage />
                </div>
            </div>

            <div className='bg-white d-flex justify-content-around mt-5 w-100' >
                <p className='w-100 border-3 d-flex fs-5 m-3 rounded card_mobile'>
                    <div className='d-flex flex-column justify-content-center me-lg-5 w-50'>
                        <img className='w-100' src={ImmagineIntro} alt="ImmagineIntro" />
                    </div>
                    <span className='p-4 text-black w-100'>
                    <span className='fs-4 text-danger fw-bold' > {domanda} </span> <br /> 
                        {testo}
                    </span>
                </p>
            </div>

            {!!altroContenutoPreGallery &&
                <div className='w-100'>
                    {altroContenutoPreGallery}
                </div> 
            }



            <div className='bg-white d-flex justify-content-around mt-5 w-100 flex-wrap p-3' >
                {arrayImageGallery.length > 0 && arrayImageGallery.map((image, key) => {
                    console.log(arrayClassImageGallery['img'+(key+1)] , 'img'+(key+1), 'Object.keys(image)', arrayClassImageGallery);
                    return(
                        <div className='d-flex mb-5 flex-column justify-content-center me-5 image_wrap'>
                            <img className={'w-100 '+  arrayClassImageGallery['img'+(key+1)] } src={image} alt={"Immagine"+key} />
                        </div>
                    )
                })
                }
                <span className='text-dark w-100 fs-6 text-center'>Esempi di realizzazione </span>
            </div>
        </div>
    )
}