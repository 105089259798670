import img1 from '../img/oscuramentoVetri/img1.webp';
import img2 from '../img/oscuramentoVetri/img2.webp';
import img3 from '../img/oscuramentoVetri/img3.webp';
import img4 from '../img/oscuramentoVetri/img4.webp';
import img5 from '../img/oscuramentoVetri/img5.webp';
import img6 from '../img/oscuramentoVetri/img6.webp';
import img7 from '../img/oscuramentoVetri/img7.webp';
import img8 from '../img/oscuramentoVetri/img8.webp';
import img9 from '../img/oscuramentoVetri/img9.webp';
import img10 from '../img/oscuramentoVetri/img10.webp';
import img11 from '../img/oscuramentoVetri/img11.webp';
import img12 from '../img/oscuramentoVetri/img12.webp';
import img13 from '../img/oscuramentoVetri/img13.webp';
import img14 from '../img/oscuramentoVetri/img14.webp';

let arrayImg = [
    img1 ,
    img2 ,
    img3 ,
    img4 ,
    img5 ,
    img6 ,
    img7 ,
    img8 ,
    img9 ,
    img10,
    img11,
    img12,
    img13,
    img14

]

let arrayClassImageGallery = {
    img1 : 'ration_rigth my-5 pt-5',
    img2 : 'ration_rigth my-5 pt-5',
    img3 : '',
    img4 : '',
    img5 : '',
    img6 : '',
    img7 : '',
    img8 : '',
    img9 : '',
    img10 : '',
    img11 : '',
    img12 : '',
    img13 : '',
    img14 : ''
}

export default function arrayImgText(params) {
    const resp = {
        IT :{
            titolo : 'OSCURAMENTO VETRI' ,
            domanda : 'NON SOTTOVALUTARE UN VETRO OSCURATO. TI SORPRENDERA’!',
            testo : 
                <div>
                L’oscuramento vetri consiste nell’applicazione di una particolare pellicola adesiva al
                fine di oscurare il cristallo per scopi sia estetici che pratici.
                La sua principale funzione è quella di protezione solare, infatti le pellicole applicate
                riescono a trattenere fino al 99% dei raggi UV.
                Molteplici sono i vantaggi dell’oscuramento vetri.
                Esteticamente si otterrà un look più sportivo, mentre a livello funzionale si avranno:
                <br/>-abbattimento raggi solari
                <br/>-maggior sicurezza
                <br/>-maggior privacy
                <br/>-abbattimento temperature elevate
                <br/>-conservazione dell’integrità del veicolo
                <br/>-possibilità di rimozione in qualsiasi momento<br/>
                La Wrap&amp;co per ogni installazione rilascerà il certificato di omologazione stradale
                per la pellicola applicata, in conformità con le normative vigenti in materia.
                Nella nostra sede si potranno visionare le differenti tonalità di pellicole che offriamo
                ai clienti.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img12,
            arrayClassImageGallery
            
        } ,
        EN :{
            titolo : 'OSCURAMENTO VETRI' ,
            domanda : "DON'T UNDERESTIMATE TINTED GLASS. IT WILL SURPRISE YOU!",
            testo : 
                <div>
                Glass tinting consists of applying a special adhesive film to darken the glass for both aesthetic
                and practical purposes.
                Its main function is sun protection, as the applied film can retain up to 99% of UV rays.
                The benefits of window tinting are manifold.
                Aesthetically, you will get a sportier look, while functionally you will have:

                <br/>-reduction of sunlight 
                <br/>-greater security
                <br/>-increased privacy
                <br/>-reduction of high temperatures
                <br/>-preservation of vehicle integrity
                <br/>-possibility of removal at any time<br/>
                For each installation, Wrap&co will issue a road homologation certificate for the film applied,
                in compliance with the relevant regulations.
                The different shades of films we offer to customers can be viewed at our premises.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img12,
            arrayClassImageGallery
            
        } ,
        SP :{
            titolo : 'TINTADO DE CRISTALES' ,
            domanda : "NO SUBESTIME LOS CRISTALES TINTADOS. ¡LE SORPRENDERÁN!",
            testo : 
                <div>
                El tintado de cristales consiste en aplicar una película adhesiva especial para oscurecer el cristal con fines estéticos y prácticos.
                Su función principal es la protección solar, ya que la película aplicada puede retener hasta el 99% de los rayos UV.
                Las ventajas del tintado de lunas son múltiples.
                Estéticamente, obtendrá un aspecto más deportivo, mientras que funcionalmente tendrá:

                <br/>-reducción de la luz solar
                <br/>-mayor seguridad
                <br/>-mayor privacidad
                <br/>-reducción de las altas temperaturas
                <br/>-preservación de la integridad del vehículo
                <br/>-posibilidad de destitución en cualquier momento<br/>
                Para cada instalación, Wrap&co emitirá un certificado de homologación vial de la lámina aplicada, conforme a la normativa vigente.
                Los distintos tonos de películas que ofrecemos a los clientes pueden verse en nuestras instalaciones.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img12,
            arrayClassImageGallery
            
        } ,
    }
    
    return resp
    
}
