import img1  from '../img/wrapping/img1.webp';
import img2  from '../img/wrapping/img2.webp';
import img3  from '../img/wrapping/img3.webp';
import img4  from '../img/wrapping/img4.webp';
import img5  from '../img/wrapping/img5.webp';
import img6  from '../img/wrapping/img6.webp';
import img7  from '../img/wrapping/img7.webp';
import img8  from '../img/wrapping/img8.webp';
import img9  from '../img/wrapping/img9.webp';
import img10  from '../img/wrapping/img10.webp';
import img11  from '../img/wrapping/img11.webp';
import img12  from '../img/wrapping/img12.webp';
import img13  from '../img/wrapping/img13.webp';
import img14  from '../img/wrapping/img14.webp';
import img15  from '../img/wrapping/img15.webp';
import img16  from '../img/wrapping/img16.webp';
import img17  from '../img/wrapping/img17.webp';
import img18  from '../img/wrapping/lambo.webp';

import banner_car_2  from '../img/banner_car_2.png';
import carO  from '../img/carO.png';
import carM  from '../img/carM.png';
import { ImgComparisonSlider } from '@img-comparison-slider/react';
import logo  from '../img/wrap&co vettoriale.png';

import video1  from '../img/wrapping/IMG_6642.MP4';

let arrayImg = [
    img1 ,
    img2 ,
    img3 ,
    img4 ,
    img5 ,
    img6 ,
    img7 ,
    img8 ,
    img9 ,
    img10,
    img11,
    img12,
    img13,
    img14 ,
    img15 ,
    img16 ,
    img17 ,
    img18 ,

]

let arrayClassImageGallery = {
    img1 : '' ,
    img2 : '' ,
    img3 : 'ration_rigth' ,
    img4 : '' ,
    img5 : '' ,
    img6 : '' ,
    img7 : '' ,
    img8 : '' ,
    img9 : '' ,
    img10 : '' ,
    img11 : '' ,
    img12 : '' ,
    img13 : '' ,
    img14 : 'ration_rigth' ,
    img15 : '' ,
    img16 : '' ,
    img17 : '' ,
    img18 : '' ,

}







export default function WrappingText(params) {
    const resp = {
        IT : {
            titolo : 'Wrapping' ,
            domanda : 'PERCHÉ VERNICIARE QUANDO SI PUO’ PELLICOLARE?',
            testo : 
                <div>
                    E’ una tecnica che permette di rivestire il proprio mezzo (auto, moto, barca, camper,
                    ecc) attraverso l’applicazione di una particolare pellicola adesiva.
                    Il wrapping non deve essere necessariamente un rivestimento totale, ma può
                    essere anche utilizzato per applicazioni parziali e mirate, come ad esempio tetti,
                    specchietti, accessori interni, non alterando il colore base originale.
                    In questi ultimi anni il mondo delle pellicole ha preso sempre più piede riuscendo
                    oggi ad offrire una vastissima gamma di colori, finiture e trame.
                    Il processo del wrapping risulta più rapido ed economico rispetto alla tradizionale
                    verniciatura ed ha il vantaggio di permettere la rimozione della pellicola in qualsiasi
                    momento lasciando perfettamente inalterata e protetta la vernice originale
                    sottostante.
                    Nella sede dela Wrap&amp;co si possono visionare oltre cento tipi di finiture tutte atte a
                    personalizzare il mezzo, dal colore lucido a quello opaco, dal metallizzato al
                    cromato, dal flip al glitterato ed altre ancora.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img12,
            arrayClassImageGallery ,
            altroContenutoPreGallery :
            <div className='align-items-center d-flex flex-column m-4'>
                <ImgComparisonSlider>
                    <img className='w-100' alt='img1' slot="first" src={carO} />
                    <img className='w-100' alt='img2' slot="second" src={carM} />
                </ImgComparisonSlider>
                <div className='w-lg-25 align-items-center d-flex flex-column'>
                    <img className='w-50' src={logo} alt="logo" />
                </div>

                <div className='align-items-center d-flex flex-column m-4'>
                    <video width="460" height="350" controls>
                        <source src={video1} type="video/mp4" />
                    </video>
                </div>
            </div>
        },
        EN : {
            titolo : 'Wrapping' ,
            domanda : 'WHY PAINT IT WHEN YOU CAN WRAP IT?',
            testo : 
                <div>
                    This is a technique that allows you to wrap your vehicle (car, motorbike, boat, camper van,
                    etc.) by applying a special adhesive film.
                    Wrapping does not necessarily have to be a total covering, but can also be used for partial
                    and targeted applications, such as roofs, mirrors, interior accessories, without altering the
                    original base colour.
                    In recent years, the world of films has become increasingly popular, and now offers a huge
                    range of colours, finishes and textures.
                    The wrapping process is quicker and cheaper than traditional painting and has the advantage
                    of allowing the film to be removed at any time while leaving the original paint underneath
                    perfectly unaltered and protected.
                    At Wrap&co's premises you can see over a hundred types of finishes all suitable for
                    customising your vehicle, from gloss to matt, from metallic to chrome, from flip to glitter and
                    more .
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img12,
            arrayClassImageGallery ,
            altroContenutoPreGallery :
            <div className='align-items-center d-flex flex-column m-4'>
                <ImgComparisonSlider>
                    <img className='w-100' alt='img1' slot="first" src={carO} />
                    <img className='w-100' alt='img2' slot="second" src={carM} />
                </ImgComparisonSlider>
                <div className='w-lg-25 align-items-center d-flex flex-column'>
                    <img className='w-50' src={logo} alt="logo" />
                </div>

                <div className='align-items-center d-flex flex-column m-4'>
                    <video width="460" height="350" controls>
                        <source src={video1} type="video/mp4" />
                    </video>
                </div>
            </div>
        } ,
        SP : {
            titolo : 'ENVOLTORIO' ,
            domanda : '¿POR QUÉ PINTARLO CUANDO SE PUEDE ENVOLVERLO?',
            testo : 
                <div>
                    Se trata de una técnica que le permite envolver su vehículo (coche, moto, barco, autocaravana, etc.) aplicando una película adhesiva especial.
                    El envoltorio no tiene qué ser total, sino que también puede utilizarse para aplicaciones parciales y específicas, como techos, espejos o accesorios interiores, sin alterar el color base original.
                    En los últimos años, el mundo de las películas se ha hecho cada vez más popular y ahora ofrece una enorme gama de colores, acabados y texturas.
                    El proceso de envoltura es más rápido y barato que la pintura tradicional y tiene la ventaja de permitir retirar la película en cualquier momento, dejando la pintura original debajo perfectamente inalterada y protegida.
                    En las instalaciones de Wrap&co podrá ver más de cien tipos de acabados, todos ellos adecuados para personalizar su vehículo, desde brillantes a mates, desde metalizados a cromados, desde flip a glitter y mucho más.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img12,
            arrayClassImageGallery ,
            altroContenutoPreGallery :
            <div className='align-items-center d-flex flex-column m-4'>
                <ImgComparisonSlider>
                    <img className='w-100' alt='img1' slot="first" src={carO} />
                    <img className='w-100' alt='img2' slot="second" src={carM} />
                </ImgComparisonSlider>
                <div className='w-lg-25 align-items-center d-flex flex-column'>
                    <img className='w-50' src={logo} alt="logo" />
                </div>

                <div className='align-items-center d-flex flex-column m-4'>
                    <video width="460" height="350" controls>
                        <source src={video1} type="video/mp4" />
                    </video>
                </div>
            </div>
        } ,
    }
    
    return resp
}