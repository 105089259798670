import React from 'react';

import '../../css/Page.css'
import ReactWhatsapp from 'react-whatsapp';
import WhatsApp_white from '../../img/WhatsApp_white.png'

import { faEnvelope, faMapLocation, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Map from './Map';




export default function Contatti() {



    return(
        <div>
            <div className='w-100 image_officina_bk'>
                <div  className='titlePage'>
                   <h1 style={{ fontSize : '4rem' }} >Contatti </h1>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='m-5 d-flex flex-culom flex-wrap' >
                    <div className='border d-inline-block p-3 pt-5 m-3'>
                        {/* <h2>Contatti</h2> */}
                        <div className="d-flex flex-column">
                            <a className='fs-3 text-decoration-none link_social pb-3 pt-3' href='tel:3791051477'>
                                <FontAwesomeIcon 
                                    className='loghi fs-4' 
                                    style={{margin:'0 22px'}} 
                                    icon={faPhone} 
                                />
                                <h5 className='m-0'>3791051477</h5>
                            </a>
                            <a
                                className='link_social m-lg-2 m-md-2 mt-2 pb-3'
                                href= "mailto:infowrapandco@gmail.com"
                            >
                                <FontAwesomeIcon 
                                    className='loghi fs-4' 
                                    style={{margin:'0 15px'}} 
                                    icon={faEnvelope} 
                                />
                                <h5 className='m-0'>infowrapandco@gmail.com</h5>
                            </a>
                            
                            <ReactWhatsapp 
                                number="+393791051477" 
                                message="Ciao vorrei informazioni su"
                                className='btn d-flex btn_bug pb-3'
                            >
                                <img 
                                    src={WhatsApp_white}
                                    alt="whatsapp" 
                                    style={{ width: '32px' , margin: '0px 7px'}} 
                                />
                                <h5 className='m-0 text-white text-decoration-none'>3791051477</h5>

                            </ReactWhatsapp>

                            <a
                                href='https://www.google.com/maps/place/Wrap%26co./@43.2963123,13.7309365,17z/data=!4m14!1m7!3m6!1s0x13326d069cd0f2f5:0x4e7a592bf27c5c0b!2sWrap%26co.!8m2!3d43.2963123!4d13.7331252!16s%2Fg%2F11szbw2c3c!3m5!1s0x13326d069cd0f2f5:0x4e7a592bf27c5c0b!8m2!3d43.2963123!4d13.7331252!16s%2Fg%2F11szbw2c3c'
                                className='link_social m-lg-2 m-md-2 mt-2'
                                target='_blank'
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    className='loghi fs-4' 
                                    style={{margin:'0 9px'}} 
                                    icon={faMapLocation}
                                />
                                <h6 className='m-0 w-100' >
                                    Via Rosolino Pilo, 45, 62012 Civitanova Marche MC
                                </h6>
                            </a>
                            
                        </div>

                        
                    </div>

                    <div className='m-3'>
                        <Map />
                    </div>
                </div>
            </div>
        </div>
    )
    
}