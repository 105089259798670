import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/js/bootstrap.esm';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Homepage from './page/Homepage';
import PaginaNonEsistente from './page/PaginaNonEsistente';
import Home from './page/sezioni/Home';
import PageController from './page/PageController';


import oscuramentoVetriText  from './testi/OscuramentoVetriText';
import WrappingText  from './testi/WrappingText';
import ImbarcazioniText  from './testi/ImbarcazioniText';
import DetailingText  from './testi/DetailingText';
import PpfText  from './testi/PpfText';
import InteriorDesignText  from './testi/InteriorDesignText';
import ControlloSolareText  from './testi/ControlloSolareText';
import SicurezzaText  from './testi/SicurezzaText';
import Contatti from './page/sezioni/Contatti';

//test
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
  integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
  crossorigin="anonymous"
/>
//\test
const lingua = localStorage.getItem("lingua") === null ? 'IT' : localStorage.getItem("lingua");
console.log(lingua , '');
const router = createBrowserRouter([
  {
    path: "/",
    element: <div><Homepage /></div>,
    errorElement: <Homepage Main={PaginaNonEsistente} />,
    children: [
      {
        path: "/",
        element: <div> <Home /> </div>,
      },
      {
        path: "Home",
        element: <div> <Home /> </div>,
      },
      {
        path: "Wrapping",
        element: <PageController
          titolo={WrappingText()[lingua].titolo}
          domanda={WrappingText()[lingua].domanda}
          testo={WrappingText()[lingua].testo}
          ImmagineIntro={WrappingText()[lingua].ImmagineIntro}
          altroContenutoPreGallery={WrappingText()[lingua].altroContenutoPreGallery}
          arrayImageGallery={WrappingText()[lingua].arrayImageGallery}
          arrayClassImageGallery={WrappingText()[lingua].arrayClassImageGallery}
        />,
      },
      {
        path: "PPF",
        element: <PageController
                    titolo={PpfText()[lingua].titolo}
                    domanda={PpfText()[lingua].domanda}
                    testo={PpfText()[lingua].testo}
                    altroContenutoPreGallery={PpfText()[lingua].altroContenutoPreGallery}
                    ImmagineIntro={PpfText()[lingua].ImmagineIntro}
                    arrayImageGallery={PpfText()[lingua].arrayImageGallery}
                    arrayClassImageGallery={PpfText()[lingua].arrayClassImageGallery}
                  />,
      },
      {
        path: "OscuramentoVetri",
        element: <PageController
                    titolo={oscuramentoVetriText()[lingua].titolo}
                    domanda={oscuramentoVetriText()[lingua].domanda}
                    testo={oscuramentoVetriText()[lingua].testo}
                    ImmagineIntro={oscuramentoVetriText()[lingua].ImmagineIntro}
                    arrayImageGallery={oscuramentoVetriText()[lingua].arrayImageGallery}
                    arrayClassImageGallery={oscuramentoVetriText()[lingua].arrayClassImageGallery}
                  />,
      },
      {
        path: "Detailing",
        element: <PageController
                    titolo={DetailingText()[lingua].titolo}
                    domanda={DetailingText()[lingua].domanda}
                    testo={DetailingText()[lingua].testo}
                    ImmagineIntro={DetailingText()[lingua].ImmagineIntro}
                    arrayImageGallery={DetailingText()[lingua].arrayImageGallery}
                    arrayClassImageGallery={DetailingText()[lingua].arrayClassImageGallery}
                  />,
      },
      {
        path: "Imbarcazioni",
        element: <PageController
                    titolo={ImbarcazioniText()[lingua].titolo}
                    domanda={ImbarcazioniText()[lingua].domanda}
                    testo={ImbarcazioniText()[lingua].testo}
                    ImmagineIntro={ImbarcazioniText()[lingua].ImmagineIntro}
                    altroContenutoPreGallery={ImbarcazioniText()[lingua].altroContenutoPreGallery}
                    arrayImageGallery={ImbarcazioniText()[lingua].arrayImageGallery}
                  />,
      },
      {
        path: "ControlloSolare",
        element: <PageController
                    titolo={ControlloSolareText()[lingua].titolo}
                    domanda={ControlloSolareText()[lingua].domanda}
                    testo={ControlloSolareText()[lingua].testo}
                    ImmagineIntro={ControlloSolareText()[lingua].ImmagineIntro}
                    altroContenutoPreGallery={ControlloSolareText()[lingua].altroContenutoPreGallery}
                    arrayImageGallery={ControlloSolareText()[lingua].arrayImageGallery}
                    arrayClassImageGallery={ControlloSolareText()[lingua].arrayClassImageGallery}
                  />,
      },
      {
        path: "InteriorDesign",
        element: <PageController
                    titolo={InteriorDesignText()[lingua].titolo}
                    domanda={InteriorDesignText()[lingua].domanda}
                    testo={InteriorDesignText()[lingua].testo}
                    ImmagineIntro={InteriorDesignText()[lingua].ImmagineIntro}
                    altroContenutoPreGallery={InteriorDesignText()[lingua].altroContenutoPreGallery}
                    arrayImageGallery={InteriorDesignText()[lingua].arrayImageGallery}
                  />,
      },
      {
        path: "Sicurezza",
        element: <PageController
                    titolo={SicurezzaText()[lingua].titolo}
                    domanda={SicurezzaText()[lingua].domanda}
                    testo={SicurezzaText()[lingua].testo}
                    ImmagineIntro={SicurezzaText()[lingua].ImmagineIntro}
                    altroContenutoPreGallery={SicurezzaText()[lingua].altroContenutoPreGallery}
                    arrayImageGallery={SicurezzaText()[lingua].arrayImageGallery}
                  />,
      },
      {
        path: "Contatti",
        element: <Contatti /> ,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    {/* <Homepage /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
