import React from 'react';

export default function PaginaNonEsistente() {
    return(
        <div>
            
            <h1>
                Pagina non esistente
            </h1>
        </div>
    )
}