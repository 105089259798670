import imgSicurezza1 from '../img/sicurezza/img1.webp'
import imgSicurezza2 from '../img/sicurezza/img2.webp'
import imgSicurezza3 from '../img/sicurezza/img3.webp'
import imgSicurezza4 from '../img/sicurezza/img4.webp'

export default function SicurezzaText(params) {
    const resp = {
        IT :{
            titolo : 'SICUREZZA' ,
            domanda : 'SENTITI SICURO (E A NORMA) A CASA TUA!',
            testo : 
                <div>
                    La Wrap&amp;co è in grado di fornire una protezione importante contro i rischi di
                    scheggiatura e rottura di vetrate attraverso particolari pellicole di sicurezza.
                    Queste pellicole sono costituite da un film in poliestere trattato composto da più
                    strati, il quale trasforma il vecchio vetro in “VETRATA DI SICUREZZA” a norma di
                    legge e normativa CEE.
                    Nel caso in cui la sostituzione dei vecchi infissi (vetri) costituisse una spesa onerosa,
                    l’applicazione di queste pellicole di sicurezza risulterebbe l’unica soluzione veloce,
                    economica e certificata.
                </div> ,
            arrayImageGallery : [
                imgSicurezza2 ,
                imgSicurezza3 ,
                imgSicurezza4 ,
                imgSicurezza1 ,
            ],
            ImmagineIntro : imgSicurezza4
            
        } ,
        EN :{
            titolo : 'SAFETY' ,
            domanda : 'FEEL SAFE (AND COMPLIANT) AT HOME!',
            testo : 
                <div>
                    Wrap&co is able to provide important protection against the risks of splintering and breakage
                    of glazing through special safety films.
                    These films consist of a multilayer treated polyester film, which transforms old glass into
                    'SAFETY GLASS' in accordance with the law and EEC regulations.
                    Should the replacement of old windows (glass) be a costly expense, the application of these
                    safety films would be the only quick, inexpensive and certified solution.
                </div> ,
            arrayImageGallery : [
                imgSicurezza2 ,
                imgSicurezza3 ,
                imgSicurezza4 ,
                imgSicurezza1 ,
            ],
            ImmagineIntro : imgSicurezza4
            
        } ,
        SP :{
            titolo : 'SEGURIDAD' ,
            domanda : 'SIÉNTASE SEGURO (Y CONFORME) EN CASA.',
            testo : 
                <div>
                Wrap&co ofrece una importante protección contra los riesgos de desportilladura y rotura de cristales mediante películas de seguridad especiales.
                Estas películas consisten en un poliéster tratado multicapa, que transforma el vidrio viejo en "ACRISTALAMIENTO DE SEGURIDAD" de acuerdo con la ley y la normativa de la CEE.
                En caso de que la sustitución de las viejas ventanas (cristales) sea un gasto costoso, la aplicación de estas películas de seguridad sería la única solución rápida, barata y certificada.
                </div> ,
            arrayImageGallery : [
                imgSicurezza2 ,
                imgSicurezza3 ,
                imgSicurezza4 ,
                imgSicurezza1 ,
            ],
            ImmagineIntro : imgSicurezza4
            
        } ,
    }
    return resp
    
}