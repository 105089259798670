import img1 from '../img/DETAILING/img1.webp';
import img2 from '../img/DETAILING/img2.webp';
import img3 from '../img/DETAILING/img3.webp';
import img4 from '../img/DETAILING/img4.webp';
import img5 from '../img/DETAILING/img5.webp';
import img6 from '../img/DETAILING/img6.webp';
import img7 from '../img/DETAILING/img7.webp';
import img8 from '../img/DETAILING/img8.webp';
import img9 from '../img/DETAILING/img9.webp';
import img10 from '../img/DETAILING/img10.webp';
import img11 from '../img/DETAILING/img11.webp';
import img12 from '../img/DETAILING/img12.webp';
import img13 from '../img/DETAILING/img13.webp';
import img14 from '../img/DETAILING/img14.webp';
import img15 from '../img/DETAILING/img15.webp';
import img16 from '../img/DETAILING/img16.webp';
import img17 from '../img/DETAILING/img17.webp';
import img18 from '../img/DETAILING/img18.webp';
import img19 from '../img/DETAILING/img19.webp';
import img20 from '../img/DETAILING/img20.webp';


export default function DetailingVetriText(params) {

    let arrayImg = [
        img1,
        img2,
        img3,
        img4,
        img5,
        img6,
        img7,
        img8,
        img9,
        img10 ,
        img11 ,
        img12 ,
        img13 ,
        img14 ,
        img15 ,
        img16 ,
        img17 ,
        img18 ,
        img19 ,
        img20 
    ]

    let arrayClassImageGallery = {
        img1: '',
        img2: '',
        img3: 'ration_rigth my-5 pt-5',
        img4: '',
        img5: '',
        img6: '',
        img7: '',
        img8: '',
        img9: '',
        img10 : '',
        img11 : '',
        img12 : '',
        img13 : '',
        img14 : '',
        img15 : '',
        img16 : 'ration_rigth',
        img17 : '',
        img18 : 'ration_rigth',
        img19 : '',
        img20: '' 
    }

    const resp ={ 
        IT: {
            titolo : 'Detailing' ,
            domanda : 'A FINE LAVORO ACCAREZZAMI… SONO RINATA!',
            testo : 
                <div>
                    E’ un tipo di “restauro della vernice” ottenuto attraverso lucidature di tipo
                    professionale.
                    Alla Wrap&amp;co potrete trovare un servizio di lucidature e trattamenti nanoceramici al
                    passo con i nuovi prodotti presenti sul mercato, per garantire al meglio la qualità e
                    la durata nel tempo.<br /> 
                    <br /> 
                    La nanotecnologia che viene applicata nella nostra sede è mirata a creare un
                    rivestimento estremamente solido amplificando ed esaltando la brillantezza e la
                    profondità della vernice sottostante.<br /> 
                    I vantaggi di un ottimo coating nanoceramico sono:<br /> 
                    -idrorepellenza<br /> 
                    -oleorepellenza<br /> 
                    -proprietà antimacchia (contro insetti ed escrementi di uccelli)<br /> 
                    -maggiore resistenza ai graffi (swirl e rids)<br /> 
                    -maggior brillantezza (risalta la vernice sottostante)<br /> 
                    -resistenza ai raggi UV (previene lo scolorimento)<br /> 
                    -facilità di pulizia ed asciugatura
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1,
            arrayClassImageGallery 
        } ,
        EN: {
            titolo : 'Detailing' ,
            domanda : 'AT THE END OF THE JOB CARESS ME... I AM REBORN!',
            testo : 
                <div>
                    This is a type of 'paint restoration' achieved through professional polishing.
                    At Wrap&co you will find a polishing service and nanoceramic treatments in line with the
                    newest products on the market, in order to best guarantee quality and durability.<br /> 
                    <br /> 
                    The nanotechnology applied in our premises is aimed at creating an extremely solid coating
                    by amplifying and enhancing the brilliance and depth of the underlying paint.<br /> 
                    The advantages of an excellent nanoceramic coating are:<br /> 
                    -water repellency <br /> 
                    -oleophobicity<br /> 
                    -anti-stain properties (against insects and bird droppings)<br /> 
                    -greater scratch resistance (swirls and rids)<br /> 
                    -greater gloss (enhances the underlying paintwork)<br /> 
                    -UV resistance (prevents discolouration)<br /> 
                    -ease of cleaning and drying
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1,
            arrayClassImageGallery
        } ,
        SP: {
            titolo : 'Detailing' ,
            domanda : 'AL FINAL DEL TRABAJO ACARÍCIAME... ¡SOY RENACIDA!',
            testo : 
                <div>
                    Se trata de un tipo de "restauración de la pintura" que se consigue mediante un pulido profesional.
                    En Wrap&co encontrará un servicio de pulido y tratamientos nano cerámicos a la altura de los nuevos productos del mercado, para garantizar al máximo su calidad y durabilidad.<br /> 
                    <br /> 
                    La nanotecnología aplicada en nuestras instalaciones tiene por objeto crear un revestimiento extremadamente sólido amplificando y realzando el brillo y la profundidad de la pintura subyacente.<br /> 
                    Las ventajas de un revestimiento nanocerámico excelente son:<br /> 
                    -repelencia al agua<br /> 
                    -oleofobia<br /> 
                    -propiedades antimanchas (contra insectos y excrementos de pájaros)<br /> 
                    -mayor resistencia a los arañazos (swirls y rids)<br /> 
                    -mayor brillo (realza la pintura subyacente)<br /> 
                    -Resistencia a los rayos UV (evita la decoloración)<br /> 
                    -facilidad de limpieza y secado
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1,
            arrayClassImageGallery
        } ,
    }
    
    return resp
    
}
