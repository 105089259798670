import React from 'react';

export default function Card({
    text ,
    title ,
    logo
}) {
    
    return(
        <div className='p-3' >
            <div class="card mb-3" style={{maxWidth: '540px;'}}>
                <div class="row g-0">
                    <div class="col-md-4">
                    <img style={{maxHeight: '400px'}} src={logo} class="img-fluid rounded-start float-start rounded-end " alt="prova" />
                    </div>
                    <div class="col-md-8">
                    <div class="card-body text-black text-center">
                        <h5 class="card-title">{title}</h5>
                        <p class="card-text w-100 text_smal">
                            {text}
                            <p className='text-primary  fs-4'>...</p>
                        </p>
                        {/* <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> */}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}