import img1 from '../img/interior/img1.webp';
import img2 from '../img/interior/img2.webp';
import img3 from '../img/interior/img3.webp';
import img4 from '../img/interior/img4.webp';
import img5 from '../img/interior/img5.webp';
import img6 from '../img/interior/img6.webp';
import img7 from '../img/interior/img7.webp';
import img8 from '../img/interior/img8.webp';
import img9 from '../img/interior/img9.webp';
import img10 from '../img/interior/img10.webp';
import img11 from '../img/interior/img11.webp';
import img12 from '../img/interior/img12.webp';
import img13 from '../img/interior/img13.webp';

let arrayImg = [
    img1 ,
    img2 ,
    img3 ,
    img4 ,
    img5 ,
    img6 ,
    img7 ,
    img8 ,
    img9 ,
    img10,
    img11,
    img12,
    img13
]


export default function InteriorDesignText(params) {
    const resp = {
        IT : {
            titolo : 'INTERIOR DESIGN' ,
            domanda : 'NUOVA VITA AL TUO ARREDO!',
            testo : 
                <div>
                    Il nostro personale altamente formato e specializzato mette a disposizione le
                    proprie capacità anche nel settore dell’arredamento, da quello privato a quello
                    commerciale e professionale.
                    E’ possibile pellicolare quasi tutti i tipi di supporto, a patto che quest’ultimo sia
                    idoneo a ricevere il vinile.
                    Queste particolari pellicole sono disponibili in una vastissima gamma, sia di colore,
                    di finitura, di trama, 2D e 3D, ecc…
                    Gran parte di queste pellicole sono cosi performanti da essere anche antigraffio,
                    caratteristica che le rende idonee ai rivestimenti dei top delle cucine!
                    Vengono normalmente applicate su armadi, cucine e mobilio in genere, evitando la
                    sostituzione che risulterebbe assai dispendiosa.
                    Oltre alle pellicole decorative per mobilio, sono anche presenti pellicole per tutti i
                    tipi di vetro, che creano un’atmosfera davvero unica e personale: esse sono
                    disponibili in moltissimi effetti e colori.
                    Contattaci per ricevere maggiori info.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1
            
        
        } ,
        EN : {
            titolo : 'INTERIOR DESIGN' ,
            domanda : 'NEW LIFE TO YOUR FURNITURE!',
            testo : 
                <div>
                    Our highly trained and specialised personnel also make their skills available in the interior
                    design sector, from private to commercial and professional bodies.
                    It is possible to wrap almost any type of substrate, provided that the substrate is suitable to
                    receive the vinyl.
                    These particular films are available in a very wide range of colours, finishes, textures, 2D and
                    3D, etc.
                    Most of these films are so high-performance that they are also scratch-resistant, which makes
                    them suitable for kitchen countertops!
                    They are normally applied to cabinets, kitchens and furniture in general, avoiding costly
                    replacement.
                    In addition to decorative films for furniture, there are also films for all types of glass, which
                    create a truly unique and personal atmosphere: they are available in many effects and colours.
                    Contact us for more information.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1
            
        
        },
        SP : {
            titolo : 'DISEÑO INTERIOR' ,
            domanda : '¡NUEVA VIDA A TUS MUEBLES!',
            testo : 
                <div>
                    Nuestro personal, altamente formado y especializado, también pone sus competencias a disposición del sector del diseño de interiores, desde el privado hasta el comercial y profesional.
                    Es posible envolver casi cualquier tipo de sustrato, siempre que éste sea adecuado para recibir el vinilo.
                    Estas películas en concreto están disponibles en una gama muy amplia de colores, acabados, texturas, 2D y 3D, etc.
                    La mayoría de estas películas son de tan alto rendimiento que también son resistentes a los arañazos, lo que las hace idóneas para encimeras de cocina.
                    Suelen aplicarse a armarios, cocinas y muebles en general, evitando costosas sustituciones. Además de láminas decorativas para muebles, también hay láminas para todo tipo de cristales, que crean un ambiente realmente único y personal: están disponibles en muchos efectos y colores.
                    Si desea más información, póngase en contacto con nosotros.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img1
            
        
        }
    }
    
    return resp
    
}
