import img1 from '../img/ppf/img1.webp';
import img2 from '../img/ppf/img2.webp';
import img3 from '../img/ppf/img3.webp';
import img4 from '../img/ppf/img4.webp';
import img5 from '../img/ppf/img5.webp';
import img6 from '../img/ppf/img6.webp';
import img7 from '../img/ppf/img7.webp';
import img8 from '../img/ppf/img8.webp';
import img9 from '../img/ppf/img9.webp';
import img10 from '../img/ppf/img10.webp';
import img11 from '../img/ppf/img11.webp';
import img12 from '../img/ppf/img12.webp';
import video2 from '../img/ppf/video1.MOV';

let arrayImg = [
    img1 ,
    img2 ,
    img3 ,
    img4 ,
    img5 ,
    img6 ,
    img7 ,
    img8 ,
    img9 ,
    img10,
    img11,
    img12,
]

let arrayClassImageGallery = {
    img1 : '',
    img2 : 'ration_rigth',
    img3 : '',
    img4 : 'ration_rigth',
    img5 : 'ration_rigth',
    img6 : '',
    img7 : '',
    img8 : '',
    img9 : '',
    img10 : 'ration_complete',
    img11 : '',
    img12 : ''
}
//import video2 from '../img/ppf/IMG_6643.MP4';

export default function PpfText(params) {
    const resp = {
        IT:{
            titolo : 'PPF' ,
            domanda : 'CHI VENDE PROTEZIONE DEVE POTER APPLICARE CON SICUREZZA',
            testo : 
                <div>
                    Detto anche paint protection film, è una pellicola trasparente ultra resistente che
                    applicata esercita un valido effetto protettivo contro pietrisco, insetti, atti vandalici,
                    spazzole di lavaggi…, donando una brillantezza di alto livello.
                    Il suo grande punto di forza è quello della rigenerazione: dopo l&#39;applicazione del PPF
                    tutti i graffi di lieve entità saranno rimarginati con l’irraggiamento solare e/o il
                    calore.
                    Ciò vuol dire avere sempre una macchina ad effetto specchio!
                    La Wrap&amp;co è la prima ditta a livello regionale ad essere divenuta centro esclusivo
                    d’installazione certificato per la ditta XPEL.
                    La peculiarità del fornitore XPEL consiste nel disporre di pellicole protettive per
                    carrozzeria, interni e fanaleria attraverso dei kit pre-tagliati, senza ricorrere al rifilo
                    manuale, annullando così qualsiasi possibilità di danneggiare la vernice sottostante.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img9 ,
            arrayClassImageGallery ,
            altroContenutoPreGallery :
            <div className='align-items-center d-flex flex-column m-4'>
                <video width="460" height="350" controls>
                    <source src={video2} type="video/mp4" />
                </video>
            </div>
        } ,
        EN:{
            titolo : 'PPF' ,
            domanda : 'IF YOU SELL PROTECTION, YOU MUST BE ABLE TO APPLY IT SAFELY',
            testo : 
                <div>
                    IF YOU SELL PROTECTION, YOU MUST BE ABLE TO APPLY IT SAFELY
                    Also known as paint protection film, it is an ultra-strong transparent film that, when applied,
                    exerts an effective protective effect against stone chips, insects, vandalism, washing brushes,
                    etc., providing a high level of gloss.
                    Its great strength is that of regeneration: after application of PPF all minor scratches will be
                    healed by sunlight and/or heat.
                    This means that you will always have a mirror effect machine!
                    Wrap&co is the first company in the Marche region to have become an exclusive certified
                    installation centre for the XPEL company.
                    The special feature of the XPEL supplier is that protective films for bodywork, interiors and
                    headlights are available in pre-cut kits, without the need for manual trimming, thus eliminating
                    any possibility of damaging the underlying paintwork.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img9 ,
            arrayClassImageGallery ,
            altroContenutoPreGallery : 
            <div className='align-items-center d-flex flex-column m-4'>
                <video width="460" height="350" controls>
                    <source src={video2} type="video/mp4" />
                </video>
            </div>
        } ,
        SP:{
            titolo : 'PPF' ,
            domanda : 'QUIENES VENDEN PROTECCIÓN DEBEN SER CAPACES DE APLICARLA CON SEGURIDAD',
            testo : 
                <div>
                    QUIENES VENDEN PROTECCIÓN DEBEN SER CAPACES DE APLICARLA CON SEGURIDAD
                    Desde el inglés Paint Protection Film, es una película transparente ultra resistente que, una vez aplicada, ejerce un eficaz efecto protector contra las picaduras de piedra, los insectos, el vandalismo, los cepillos de lavado, etc., proporcionando un alto nivel de brillo.
                    Su gran punto fuerte es la regeneración: tras la aplicación de PPF, todos los pequeños arañazos se curan con la luz del sol y/o el calor. Esto significa que siempre dispondrá de una máquina con efecto espejo.
                    Wrap&co es la primera empresa de la región que se ha convertido en centro de instalación certificado exclusivo de la empresa XPEL. La característica especial del proveedor XPEL es que las películas protectoras para carrocería, interiores y faros están disponibles en kits precortados, sin necesidad de recorte manual, eliminando así cualquier posibilidad de dañar la pintura subyacente.
                </div> ,
            arrayImageGallery : arrayImg,
            ImmagineIntro : img9 ,
            arrayClassImageGallery ,
            altroContenutoPreGallery : 
            <div className='align-items-center d-flex flex-column m-4'>
                <video width="460" height="350" controls>
                    <source src={video2} type="video/mp4" />
                </video>
            </div>
        } ,
    }
    
    return resp
    
}
